<template>
  <v-container class="mt-10">
    <v-row>
     <v-col
      cols="12"
      >
        <v-card-text class="headline">
          Тема: "Требования по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей"
        </v-card-text>
     </v-col>
     <v-col cols="6">
          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="theme in themes"
              :key="theme.id"
              :color="theme.color"
              small
            >
              <div>
                <div class="headline font-weight-light">{{ theme.theme }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
      </v-col>
      <!-- /theme -->
      <!-- img -->
      <v-col cols="6">
      <v-card class="pa-5 mb-5">
        <v-img
          height="200px"
          src="img/material/bg-theme-2.svg"
          contain
          class="pa-5"
        >
        </v-img>
      </v-card>
      <!-- /img -->
      <v-card flat>
        <v-card-text class="subtitle-1">
          <v-icon
          color="red"
          class="mr-3"
          >
          mdi-cursor-default-click-outline</v-icon>
          Для изучения дополнительной информации, нажимайте на кнопки или активные элементы
        </v-card-text>
        <v-card-text class="subtitle-1">
          <v-icon
          color="red"
          class="mr-3"
          >
          mdi-arrow-left-right</v-icon>
          Для перемещения по разделам используйте кнопки "Вперед" и "Назад" в виде стрелок
        </v-card-text>
      </v-card>
        <v-card flat class="d-flex justify-center mt-10">
          <v-btn
          color="red"
          dark
          to="page6"
          >
            Перейти к изучению
          </v-btn>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    themes: [
      {
        id: 1,
        theme: 'Причины пожаров в зданиях и сооружениях в РФ за 2019г',
        color: 'red'
      },
      {
        id: 2,
        theme: 'Статистика пожаров в общественных зданиях',
        color: 'red'
      },
      {
        id: 3,
        theme: 'Факторы, представляющие опасность для при пожарах',
        color: 'red'
      },
      {
        id: 4,
        theme: 'Организационные мероприятия по установлению противопожарного режима',
        color: 'red'
      },
      {
        id: 5,
        theme: 'Порядок обучения служащих мерам ПБ на рабочих местах',
        color: 'red'
      },
      {
        id: 6,
        theme: 'Создание пожарно-технических комиссий и добровольных пожарных дружин',
        color: 'red'
      }
    ]
  })
}
</script>
